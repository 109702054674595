<template>
  <section>
    <b-row class="match-height">
      <b-col md="12">
        <dashboard-seller-informations v-if="selectedOrganization.seller && $can('TRIP_ACCOUNTING_VIEW')" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import DashboardSellerInformations from '@/views/dashboard/seller/DashboardSellerInformations.vue'
import { mapState } from 'vuex'

export default {
  components: {
    DashboardSellerInformations,
  },

  computed: {
    ...mapState('auth', ['selectedOrganization']),
  },
}

</script>

<template>
  <div class="d-flex justify-content-between flex-wrap">
    <div class="d-flex flex-column justify-content-center align-items-md-center">
      <h6>
        <feather-icon
          icon="DollarSignIcon"
          class="mr-50"
          size="24"
          stroke-width="3"
        />
        {{ $t('dashboard.seller.total_balance') }}
      </h6>
      <h2 class="">{{ balance.totalConnectedAccount }} {{ currency }}</h2>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-md-center">
      <h6>
        <feather-icon
          icon="CheckIcon"
          class="mr-50"
          size="24"
          stroke-width="3"
        />
        {{ $t('dashboard.seller.bank_transfer_available') }}
      </h6>
      <h2>{{ balance.availableAmount }} {{ currency }}</h2>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-md-center">
      <h6>
        <feather-icon
          icon="RefreshCwIcon"
          class="mr-50"
          size="24"
          stroke-width="3"
        />
        {{ $t('dashboard.seller.coming_soon') }}
      </h6>
      <h2>{{ balance.pendingAmount }} {{ currency }}</h2>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DashboardSellerBalance',
  props: {
    balance: {
      type: Object,
      default: () => ({}),
    },
    currency: {
      type: String,
      default: null,
    },
  },
}

</script>

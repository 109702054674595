<template>
  <div>
    <b-overlay :show="balanceLoading">
      <b-row class="match-height balance">
        <b-col md="8">
          <b-card>
            <b-card-title class="text-uppercase">{{ $t('dashboard.seller.balance') }}</b-card-title>
            <dashboard-seller-balance :balance="balance[0]" :currency="currency" />
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card>
            <b-card-title class="text-uppercase">{{ $t('dashboard.seller.total') }}</b-card-title>
            <dashboard-seller-total :total="balance[0]" :currency="currency" />
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
    <b-row>
      <b-col md="12">
        <dashboard-seller-list />
      </b-col>
    </b-row>
  </div>
</template>

<script>

import DashboardSellerBalance from '@/views/dashboard/seller/DashboardSellerBalance.vue'
import DashboardSellerTotal from '@/views/dashboard/seller/DashboardSellerTotal.vue'
import DashboardSellerList from '@/views/dashboard/seller/DashboardSellerList.vue'
import { fetchOrganizationBalanceRequest } from '@/request/globalApi/requests/organizationRequests'
import { mapState } from 'vuex'
import CURRENCIES from '@/constants/currency'

export default {
  name: 'DashboardSellerInformations',
  components: {
    DashboardSellerBalance,
    DashboardSellerTotal,
    DashboardSellerList,
  },
  data() {
    return {
      balanceLoading: false,
      balance: [],
      currency: null,
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization']),
  },
  mounted() {
    this.APIFetchBalance()
  },
  methods: {
    APIFetchBalance() {
      this.balanceLoading = true
      fetchOrganizationBalanceRequest(this.selectedOrganization.id)
        .then(response => {
          this.balance = response.data.currencies
          // TODO: use getCurrency instead?
          if (this.balance[0]) this.currency = CURRENCIES[this.balance[0].currency.code].symbol
          this.balanceLoading = false
        })
    },
  },
}

</script>

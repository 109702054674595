<template>
  <div>
    <h6 class="icon-height">{{ $t('dashboard.seller.total_since_registration') }}</h6>
    <h2>{{ total.totalVolume }} {{ currency }}</h2>
  </div>
</template>

<script>

export default {
  name: 'DashboardSellerTotal',
  props: {
    total: {
      type: Object,
      default: () => ({}),
    },
    currency: {
      type: String,
      default: null,
    },
  },
}

</script>
<style lang="scss" scoped>
  .icon-height {
    line-height: 24px;
  }
</style>
